/**
* Template Name: NiceAdmin - v2.5.0
* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

#main {
    margin-bottom: 30px;
}

#footer {
    background-color: white;
    padding: 0px;
}


.mwx-50 { max-width: 50px !important;}
.mwx-100 { max-width: 100px!important;}
.mwx-150 { max-width: 150px!important;}
.mwx-200 { max-width: 200px!important;}
.mwx-250 { max-width: 250px!important;}
.mwx-300 { max-width: 300px!important;}
.mwx-350 { max-width: 350px!important;}
.mwx-400 { max-width: 400px!important;}
.mwx-450 { max-width: 450px!important;}
.mwx-500 { max-width: 500px!important;}
.mwx-550 { max-width: 550px!important;}
.mwx-600 { max-width: 600px!important;}
.mwx-650 { max-width: 650px!important;}
.mwx-700 { max-width: 700px!important;}
.mwx-750 { max-width: 750px!important;}
.mwx-800 { max-width: 800px!important;}
.mwx-850 { max-width: 850px!important;}
.mwx-900 { max-width: 900px!important;}
.mwx-950 { max-width: 950px!important;}
.mwx-1000 { max-width: 1000px!important;}
.mwx-1050 { max-width: 1050px!important;}
.mwx-1100 { max-width: 1100px!important;}
.mwx-1150 { max-width: 1150px!important;}
.mwx-1200 { max-width: 1200px!important;}
.mwx-1250 { max-width: 1250px!important;}
.mwx-1300 { max-width: 1300px!important;}

.mw-fit-content {
    max-width: fit-content !important;
}

.wx-100 { width: 100px!important;}

/****************
VERTICAL TIMELINE
****************/
.todo-timeline .timeline-text {
    display: flex;
    justify-content: space-between;
}

.todo-timeline .timeline-text .timeline-number {
    margin-right: 60px;
}

.todo-timeline .todo-title {
    font-size: 50px;
    color: #0fa3b1;
    font-weight: bold;
    white-space: nowrap;
}

.todo-timeline .v-timeline ul {
    position: relative;
}

.todo-timeline .v-timeline li {
    list-style-type: none;
    padding: 0 0 30px;
    position: relative;
    overflow: hidden;
}

.todo-timeline .v-timeline li .top-bold-label {
    font-size: 1.4rem;
    font-weight: bold;
}

.todo-timeline .v-timeline li .mid-paragraph {
    font-size: 1.4rem;
}

.todo-timeline .v-timeline li .bottom-link {
    color: #0fa3b1;
    font-weight: bold;
    font-size: 1.4rem;
}

.todo-timeline .v-timeline .pl-30 {
    padding-left: 30px;
}

.todo-timeline .v-timeline .v-timeline-icon:before {
    content: " ";
    height: 27px;
    position: absolute;
    top: 0;
    left: 0;
    width: 27px;
    border-radius: 50%;
    background: #257cfc;
}

.todo-timeline .v-timeline .v-timeline-icon:after {
    content: " ";
    border-left: 2px solid #257cfc;
    display: inline-block;
    position: absolute;
    left: 12px;
    height: 400px;
    z-index: 400;
    top: 27px;
}

.todo-timeline .v-timeline .v-timeline-icon.v-last:before {
    background: #d9e5d6;
}

.todo-timeline .v-timeline .v-timeline-icon.v-last:after {
    height: 0;
}

.todo-timeline .v-timeline .v-timeline-icon.v-not:before {
    background: #d9e5d6;
}

.todo-timeline .v-timeline .v-timeline-icon.v-not:after {
    border-left: 2px dashed #d9e5d6;
}

.todo-timeline .v-timeline .v-timeline-icon.v-line-color:after {
    border-left: 2px dashed #d9e5d6;
}

.todo-timeline .v-timeline .v-timeline-icon.v-end:before {
    background: #257cfc;
}

.todo-timeline .v-timeline .v-timeline-icon.v-end:after {
    height: 0;
}

.vh-75 {
    height: 75vh;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,.1);
    z-index: 9999;
}
.loader-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


@media screen and (max-width: 576px) {
    table.table-basic {
        border: 0;
    }
    table.table-basic caption {
        font-size: 1.3em;
    }
    table.table-basic thead {
        border: none;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    table.table-basic tr {
        border: 1px solid #ddd;
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }
    table.table-basic td {
        border-bottom: 1px solid #ddd;
        display: block;
        text-align: left;
    }
    table.table-basic td::before {
        content: attr(data-th);
        float: left;
        font-weight: bold;
        margin-right: 10px;
    }
    table.table-basic td:last-child {
        border-bottom: 0;
    }
}

#html5-qrcode-anchor-scan-type-change{
    display: none;
}

@media (min-width: 576px) {
    .text-sm-nowrap {
        white-space: nowrap;
    }
}

/*modal footer fijo*/
.modal-fixed .modal-dialog, .modal-fixed .modal-content{ height: 100vh!important; margin-top: 0!important; margin-bottom: 0!important;}
.modal-fixed .modal-header{ height: 58px;}
.modal-fixed .modal-footer{ height: 71px;}
.modal-fixed .modal-body{ height: calc(100vh - 130px); overflow-y: auto}